import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/red-design.svg"

const Header = ({ siteTitle }) => (
  <header
    style={{
     marginBottom: `1.45rem`,
     margin: `0 auto`,
     maxWidth: 960,

    }}
  >
    <nav 
      className ="main-nav"
      style ={{
        minHeight: 80,
      }}
    >
      
      <ul >
        <li><Link to="/about/" activeClassName ="active">About</Link></li>
        <li><Link to="/services/">Services</Link></li>
        <li><Link to="/contact/">Contact</Link></li>
        
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
